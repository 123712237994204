import SiteWrapper from "../../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../../assets/images/pagerduty_dxable_og.png";

import MdRenderer from "../../components/mdrender";
import * as md from "../../components/mdrender.module.sass";
import TitleBlock from "../../components/title";

import "./retails@gen.scss";

const GeneratedIndustriesRetails = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>
          小売業でのPagerDuty活用法｜正規販売代理店（株）Digital Stacks
        </title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="ダウンタイムは1分あたり数千万円級のコストとなり、悪影響は急速に波及します。DXによって小売業が急速に進化する中、複雑なテクノロジースタックと分散したチームは、優れたカスタマーエクスペリエンス実現のために、常に完璧に連携している必要があります。"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content="ダウンタイムは1分あたり数千万円級のコストとなり、悪影響は急速に波及します。DXによって小売業が急速に進化する中、複雑なテクノロジースタックと分散したチームは、優れたカスタマーエクスペリエンス実現のために、常に完璧に連携している必要があります。"
        />

        <meta
          property="og:title"
          content="小売業でのPagerDuty活用法｜正規販売代理店（株）Digital Stacks"
        />

        <meta
          property="og:description"
          content="ダウンタイムは1分あたり数千万円級のコストとなり、悪影響は急速に波及します。DXによって小売業が急速に進化する中、複雑なテクノロジースタックと分散したチームは、優れたカスタマーエクスペリエンス実現のために、常に完璧に連携している必要があります。"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/industries/retails/"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/industries/retails/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <TitleBlock
        classNames="md:mb-[80px] mb-[40px]"
        textYellow="Solutions"
        textGreen="小売業でのPagerDuty活用法"
        overview=""
      />

      <div className="retail-wrapper">
        <div className="center-2 first-section">
          <p className="center-para">
            ダウンタイムは1分あたり数千万円級のコストとなり、悪影響は急速に波及します。DXによって小売業が急速に進化する中、複雑なテクノロジースタックと分散したチームは、優れたカスタマーエクスペリエンス実現のために、常に完璧に連携している必要があります。
          </p>
        </div>
        <div className="text-right-1">
          <div className="text-part">
            <h3 className="text-right-title">
              素晴らしいカスタマーエクスペリエンスを提供
            </h3>
            <p className="text-right-para">
              小売業者は、決済ゲートウェイ、在庫・受注管理、ウェブサイトやモバイルアプリなど、複雑に相互接続されたサービス全体で高い可用性を確保する必要があります。特にピークシーズンには、スタッフの疲労を最小限に抑えながら稼働し続けることが非常に重要です。PagerDutyの小売業向けソリューションにより、チームは完全な可視性を達成し、問題を積極的に解決し、リアルタイムにチャンスを活かして顧客満足度を向上できます。
            </p>
          </div>
          <div className="img-container">
            <img src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/Retail_icon_175cd27ce1.png" />
          </div>
        </div>
        <div className="center-1">
          <h2 className="center-title">
            リアルタイムのオペレーションで小売業を改革
          </h2>
        </div>
        <div className="three-col-blog-card-image-grey">
          <div className="card">
            <div className="icon-section">
              <svg
                width={50}
                height={50}
                viewBox="0 0 39 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.8868 59.2752H28.2008V50.0315H10.8868V59.2752ZM14.0863 56.2984H25V53.0095H14.0863V56.2984Z"
                  fill="#005A24"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M28.0951 47.0114H24.893V40.8987C24.893 36.1783 26.5941 32.582 30.5822 28.873C33.9371 25.753 35.6382 22.1812 35.6382 18.2591C35.6382 10.0024 28.4176 3.28606 19.5408 3.28606C10.6639 3.28606 3.442 10.0024 3.442 18.2591C3.442 22.1812 5.14312 25.7518 8.49928 28.873C12.4874 32.582 14.1872 36.1783 14.1872 40.8987V47.0114H10.9864V40.8987C10.9864 36.9902 9.61053 34.1163 6.23594 30.9779C2.25833 27.2787 0.241211 22.9991 0.241211 18.2591C0.241211 8.36158 8.89822 0.308105 19.5408 0.308105C30.182 0.308105 38.8403 8.36158 38.8403 18.2591C38.8403 22.9991 36.8219 27.2787 32.8442 30.9779C29.471 34.1163 28.0951 36.9902 28.0951 40.8987V47.0114Z"
                  fill="#005A24"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M21.0373 47.0137L17.8351 47.0076L17.8918 19.6794L21.0939 19.6868L21.0373 47.0137Z"
                  fill="#005A24"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.3766 21.1714H25.553V18.1935H13.3766V21.1714Z"
                  fill="#005A24"
                />
              </svg>
            </div>
            <h4 className="title">
              オムニチャネル <br />
              エクスペリエンスの最適化
            </h4>
            <p className="para">
              モバイルPOSデバイス、レジ、ITシステムなど、複数チャネルで障害を自動的に検出してリアルタイムに対応することにより、実店舗およびデジタルでの滑らかなカスタマージャーニーを実現します。
            </p>
          </div>
          <div className="card">
            <div className="icon-section">
              <svg
                width={50}
                height={50}
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_14_2"
                  style={{ maskType: "alpha" }}
                  maskUnits="userSpaceOnUse"
                  x={0}
                  y={0}
                  width={50}
                  height={50}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.00012207 0H49.5083V49.5213H0.00012207V0Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_14_2)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.36322 24.7546C3.36322 12.9594 12.9596 3.36297 24.7549 3.36297C36.5489 3.36297 46.1466 12.9594 46.1466 24.7546C46.1466 36.5487 36.5489 46.1463 24.7549 46.1463C12.9596 46.1463 3.36322 36.5487 3.36322 24.7546ZM24.7549 -0.00012207C11.0823 -0.00012207 0.00012207 11.0832 0.00012207 24.7546C0.00012207 38.4249 11.0823 49.5082 24.7549 49.5082C30.9716 49.5082 36.6489 47.2106 40.9954 43.4261L47.0894 49.5213L49.4692 47.1439L43.3775 41.0511C47.1918 36.6963 49.5085 30.9975 49.5085 24.7546C49.5085 11.0832 38.4251 -0.00012207 24.7549 -0.00012207Z"
                    fill="#005A24"
                  />
                </g>
              </svg>
            </div>
            <h4 className="title">
              ハイブリッド運用
              <br />
              の可視化
            </h4>
            <p className="para">
              分散したチームとワークフロー（DevOps、NOC、ITSM、セキュリティー、サポートなど）のブリッジとなり、内部システムだけでなく外部サービスも含めた迅速な動員によりリスクを軽減します。
            </p>
          </div>
          <div className="card">
            <div className="icon-section">
              <svg
                width={50}
                height={50}
                viewBox="0 0 65 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M47.3029 -0.000488281L32.3291 14.972L17.3566 -0.000488281L0.000366211 17.3558L2.49662 19.8533L17.3566 4.99326L32.2166 19.8533L32.3291 19.7395L32.4404 19.8533L47.3029 4.99326L62.1616 19.8533L64.6566 17.3558L47.3029 -0.000488281Z"
                  fill="#005A24"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M32.3291 29.7816L17.3566 14.8079L0.000366211 32.1654L2.49662 34.6616L17.3566 19.8016L32.2166 34.6616L32.3291 34.5491L32.4404 34.6616L47.3029 19.8016L62.1616 34.6616L64.6566 32.1654L47.3029 14.8079L32.3291 29.7816Z"
                  fill="#005A24"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M32.3291 44.5889L17.3566 29.6151L0.000366211 46.9739L2.49662 49.4689L17.3566 34.6089L32.2166 49.4689L32.3291 49.3564L32.4404 49.4689L47.3029 34.6089L62.1616 49.4689L64.6566 46.9739L47.3029 29.6151L32.3291 44.5889Z"
                  fill="#005A24"
                />
              </svg>
            </div>
            <h4 className="title">
              より少ない労力で
              <br />
              より多くを実現
            </h4>
            <p className="para">
              最新のビジネスサービス（サイトマーチャンダイジング、チェックアウトなど）に、リアルタイムのインテリジェンス対応と自動レスポンスを導入。チームが重要な業務に専念できるようになります。
            </p>
          </div>
        </div>
        <div className="center-1 mt-0">
          <h2 className="center-title">
            リアルタイムオペレーションが小売業を強化する理由
          </h2>
        </div>
        <div className="four-col-without-cta mb-[100px] gap-[50px] md:gap-[0px]">
          <div className="card">
            <h6>顧客中心型のワークフロー</h6>
            <p>
              ライブコールルーティング、チケッティングの統合、自動エスカレーションにより、チームは24時間365日のサポートを提供できるようになります。
            </p>
          </div>
          <div className="card">
            <h6>プラットフォームの拡張性</h6>
            <p>
              500以上のネイティブ統合とオープンAPIにより、あらゆるソースからのデータを可視化し、実行可能な状態にします。
            </p>
          </div>
          <div className="card">
            <h6>企業規模のオーケストレーション</h6>
            <p>
              IT担当者とビジネスステークホルダーの自動的なオーケストレーションを実現し、顧客にとって最適なアクションを実行、ビジネスインパクトを定量化して優先順位付けと改善を実施できます。
            </p>
          </div>
          <div className="card">
            <h6>インテリジェンスと自動化</h6>
            <p>
              機械学習とベストプラクティスのレスポンス自動化によってもたらされる総合的なコンテキストによって、重要な事項に焦点を当て、顧客への悪影響を迅速に防止できます。
            </p>
          </div>
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedIndustriesRetails;
